.ts-page-home-container {
  display: flex;
  width: 100%;
  height: 100%;
}

/*org 1200px*/
.ts-page-home-content {
  flex: 1;
  min-width: 800px;
  height: 900px;
}

.ts-page-home-control {
  width: 320px;
  height: 900px;
  padding-left: 20px;
}