.ts-account-detail-control-call {

}

.ts-account-detail-control-call>p {
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;

  color: #FFFFFF;

  text-align: left;
}