.ts-history-control-call {
  width: 100%;
  height: 100%;
}

/*total height 610px fixed*/

.ts-history-control-call-1 {
  height: 200px;
  margin-bottom: 20px;
}

.ts-history-control-call-2 {
  height: 420px;
}


.ts-history-control-form {
  display: inline;
}

.ts-history-control-indicator {
  width: 100%;
  height: 110px;
}

.ts-history-control-indicator>p {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;  
  color: #FFFFFF;
}
.ts-history-control-indicator>p>span {
  font-weight: 700;
  color: #0974FF;
}

.ts-history-control-buttons {
  margin-top: 20px;
}
.ts-history-control-buttons>div {
  width: 48%;
  float: left;
  margin-bottom: 10px;
}
.ts-history-control-buttons-left {
  margin-right: 10px;
}