.ts-textarea {
  width: 100%;
  height: 100%;
}

.ts-textarea>textarea {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  color: #FFFFFF;
  background-color: #17192D;
  
  border: 1px solid #272836;
  border-radius: 5px;

  box-sizing: border-box;

  resize: none;
  color: #5E6484;

  font-family: "Roboto Mono";
  font-size: 12px;
  letter-spacing: -1px;
}

.ts-textarea-disabled {
  color: #5E6484;
}