.ts-sidemenu-account {
  margin-top: 33px;
}

.ts-sidemenu-account>p {
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  font-size: 13px;
  color: #929CCF
}