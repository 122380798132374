.ts-page-detail-container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 932px;
}

/*org 1200px*/
.ts-page-detail-content {
  flex: 1;
  min-width: 800px;
}
.ts-page-detail-content>h1 {
  margin:0;
  padding:0;
  color: white;
}

.ts-page-detail-control {
  width: 320px;
  height: 320px;
  padding-left: 20px;
}