.ts-page-select-container {
  display: flex;
  width: 100%;
  height: 100%;
}

/*org 1200px*/
.ts-page-select-content {
  flex: 1;
  min-width: 800px;
  height: 932px;
}
.ts-page-select-content>h1 {
  margin:0;
  padding:0;
  color: white;
}

/*org 932px height*/
.ts-page-select-control {
  width: 320px;
  height: 455px;
  padding-left: 20px;
}