.ts-account-editor {

}

.ts-account-editor-indicator {
  width: 100%;
  height: 24px;
  margin-bottom: 20px;
}

.ts-account-editor-indicator>p {
  float: left;
  width: 80px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;  
  color: #FFFFFF;
}
.ts-account-editor-indicator>p>span {
  font-weight: 700;
  color: #0974FF;
}

.ts-account-editor-inputarea {
  width: 100%;
}

.ts-account-editor-buttonarea {
  display: flex;
}
.ts-account-editor-buttonarea-1 {
  flex: 1;
  padding-right: 5px;
}
.ts-account-editor-buttonarea-2 {
  flex: 1;
  padding-left: 5px;
}