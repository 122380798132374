.ts-table-history-wrapper {
  width: 100%;
  height: 100%;
  max-height: 990px;
  min-height: 50px;
  overflow: scroll !important;
}

.ts-table-history {
  width: 100%;
  min-width: 640px;
  color: #FFFFFF;
  border-collapse:collapse;
  box-sizing: border-box;
  font-size: 12px;
  font-family: "Roboto Mono";
  white-space: nowrap;
}

.ts-table-history>*>tr {
  display: flex;
  height: 35px;

  border-radius: 8px;
  background-color: #17192D;
  transition: all 0.5s ease-in-out;
}

.ts-table-history-tr-odd {
  background-color: #21233B !important;
}

.ts-table-history>*>tr>th {
  padding-top: 9px;
  box-sizing: border-box;
  text-align: center !important;
  overflow: hidden;

  font-size: 12px;
  font-weight: 400 !important;
  color: #929CCF;
}
.ts-table-history>*>tr>td {
  padding-top: 9px;
  box-sizing: border-box;
  text-align: right;
  overflow-x: scroll !important;
}

.ts-table-history-r1 {
  flex: 1.1;
  min-width: 58px;
  text-align: center !important;
}
.ts-table-history-r2 {
  flex: 0.7;
  min-width: 40px;
  text-align: center !important;
}
.ts-table-history-r3 {
  flex: 2;
}
.ts-table-history-r4 {
  flex: 2;
}
.ts-table-history-r5 {
  flex: 2;
}
.ts-table-history-rn {
  flex: 0.1;
}
.ts-table-history-r6 {
  flex: 2.1;
  text-align: left !important;
}

.ts-table-history-text {
  font-family: 'Roboto Mono' !important;
}
.ts-table-history-text-td {
  padding-top: 9px !important;
}
.ts-table-history-num {
  font-family: 'Roboto Mono' ;
}

.ts-table-history-highlight-buy {
  font-weight: bold;
  color: #B52049;
}
.ts-table-history-highlight-sell {
  font-weight: bold;
  color: #0874FF;
}
.ts-table-history-highlight-etc {
  font-weight: bold;
  color: #4B9161;
}