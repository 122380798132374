.ts-account-content-list {
  width: 100%;
  padding-top: 5px;
}

.ts-account-content-list-th {
  display: flex;
  width: 100%;
  height: 20px;
  box-sizing: border-box;
}
.ts-account-content-list-th>p {
  margin-left: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #929CCF;
  box-sizing: border-box;
}

.ts-account-content-list-th-1 {
  flex: 0.39;
}
.ts-account-content-list-th-2 {
  flex: 1.06;
}
.ts-account-content-list-th-3 {
  flex: 2.02;
}
.ts-account-content-list-th-4 {
  flex: 3.05;
}
.ts-account-content-list-th-5 {
  flex: 3.05;
}
.ts-account-content-list-th-6 {
  flex: 1.5;
  padding-right: 20px;
  text-align: right;
}