.ts-page-feed-container {
  display: flex;
  width: 100%;
  height: 100%;
}

/*org 1200px*/
.ts-page-feed-content {
  flex: 1;
  min-width: 800px;
  min-height: 932px;
}

/*org 932px height*/
.ts-page-feed-control {
  width: 320px;
  height: 455px;
  padding-left: 20px;
}