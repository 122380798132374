html {
  display: block;
  width: auto;
  height: auto;
  background-color: #0A0E1B;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Atkinson Hyperlegible", "Arial", "Segoe UI", "Roboto", "Oxygen", -apple-system, BlinkMacSystemFont, 
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
}::-webkit-scrollbar {
  display: none !important;
}

input {
  font-family: "Atkinson Hyperlegible", "Arial", "Segoe UI", "Roboto", "Oxygen", -apple-system, BlinkMacSystemFont, 
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

code {
  font-family: "Atkinson Hyperlegible", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  padding: 0;
}