.ts-page-history-container {
  display: flex;
  width: 100%;
  height: 100%;
}

/*org 1200px*/
.ts-page-history-content {
  display: flex;
  flex: 1;
  min-width: 800px;
}
.ts-page-history-content-1{
  flex: 1.5;
  overflow: scroll;
}
.ts-page-history-content-2 {
  flex: 1;
  overflow: scroll;
}
.ts-page-history-content-gap {
  flex: 0.1;
  max-width: 20px;
  min-width: 20px;
}

.ts-page-history-control {
  width: 320px;
  height: 650px;
  padding-left: 20px;
}