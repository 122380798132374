.root-div {
  max-width: 1800px;
  min-width: 1360px;
  min-height: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.page-div {
  display: flex;
  width: 100%;
  height: 100%;
}

.sidemenu-container {
  width: 220px;
  height: 100%;
  padding-right: 40px;
  padding-top: 30px;
  box-sizing: border-box;
}

/*
  min-width: 1580px;
*/
.page-container {
  flex: 1;
  min-width: 50px;
  height: 100%;
  padding-top: 30px;
  box-sizing: border-box;
}