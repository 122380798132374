.ts-table-wrapper {
  width: 100%;
  height: 100%;
  
  overflow-x: scroll;
}

.ts-table {
  width: 100%;
  color: #FFFFFF;
  border-collapse:collapse;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Roboto Mono";
  white-space: nowrap;
}

.ts-table>*>tr {
  display: flex;
  height: 35px;

  border-radius: 8px;
  background-color: #17192D;
  transition: all 0.5s ease-in-out;
}

.ts-table-tr-odd {
  background-color: #21233B !important;
}

.ts-table>*>tr>th {
  padding-top: 10px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;

  font-size: 12px;
  font-weight: 400 !important;
  color: #929CCF;
}
.ts-table>*>tr>td {
  padding-top: 7px;
  box-sizing: border-box;
  text-align: right;
  overflow-x: scroll;
}

.ts-table-r1 {
  flex: 0.75;
  min-width: 50px;
  text-align: center !important;
}
.ts-table-r2 {
  flex: 1.8;
}
.ts-table-ra {
  flex: 1.2;
  text-align: left;
}
.ts-table-ra>span {
  font-size: 8px;
}
.ts-table-r3 {
  flex: 1.7;
}
.ts-table-r4 {
  flex: 1.8;
}
.ts-table-r5 {
  flex: 1.8;
  padding-right: 10px;
  box-sizing: border-box;
}

.ts-table-text {
  font-family: 'Atkinson Hyperlegible' !important;
}
.ts-table-text-td {
  padding-top: 8.5px !important;
}
.ts-table-num {
  font-family: 'Roboto Mono' ;
}

.ts-table-tr-updated {
  background-color: #2D3262 !important;
}
.ts-table-tr-odd-updated {
  background-color: #434874 !important;
}