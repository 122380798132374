.ts-btn-simple {
  width: 100%;
  height: 40px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  align-items: center;
  text-align: center;

  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;

  transition: all 0.1s ease-in-out;
  text-align: center;
}

.ts-btn-simple-dark {
  background-color: #17192D;
  border: 1px solid #383B5A; 
}
.ts-btn-simple-dark:hover {
  background-color: #2E314B; 
}

.ts-btn-simple-blue {
  background-color: #336BEA;
  border: 1px solid #336BEA
}
.ts-btn-simple-blue:hover {
  background-color: #1C4CBF;
}

.ts-btn-simple-darkblue {
  background-color: #21233B;
  border: 1px solid #1F63FF;
  color: #1F63FF;
}
.ts-btn-simple-darkblue:hover {
  background-color: #1F63FF;
  color: #FFFFFF;
}

.ts-btn-simple-adaptive {
  height: 100%;
}