.ts-accountdetail-card {
  width: 100%;
  min-height: 120px;

  margin-bottom: 10px;
  padding-top:    20px;
  padding-bottom: 30px;
  padding-left:   30px;
  padding-right:  30px;

  background: #21233B;
  border-radius: 8px;

  box-sizing: border-box;
}

.ts-accountdetail-card-labelarea {
  display: flex;
  width: 100%;
  height: 27px;
  margin-bottom: 20px;
}
.ts-accountdetail-card-labelarea-texts {
  flex: 4;
  overflow: hidden;
}
.ts-accountdetail-card-labelarea-texts>p {
  float: left;
  height: 100%;
  white-space: nowrap;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}
.ts-accountdetail-card-labelarea-texts>p>span {
  color: #336BEA;
}

.ts-accountdetail-card-labelarea-texts-time {
  font-size: 14px;
  color: #676978 !important;
}

.ts-accountdetail-card-labelarea-buttons {
  flex: 0.1;
  min-width: 80px;
  height: 100%;
  margin-left: 10px;
}

.ts-accountdetail-card-listarea {
  width: 100%;
}