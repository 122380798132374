.ts-card-pricefeed {
  flex: 1;
  min-width: 310px;
  height: 238px;
  
  background-color: #252842;
  border-radius: 8px;

  padding: 12px;
  margin: 11px;
  box-sizing: border-box;
}
@media (max-width: 600px) {
  .ts-card-pricefeed {
    width: 200px;
  }
}

.ts-card-pricefeed-headline {
  width: 100%;
  height: 33px;
  
  border-bottom: 1px solid #333757;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;

  box-sizing: border-box;
}
.ts-card-pricefeed-headline>p {
  font-size: 16px;
}
.ts-card-pricefeed-headline-exchange {
  float: left;
  color: #ffffff;
}
.ts-card-pricefeed-headline-ticker {
  float: right;
  color: #336BEA;
}


.ts-card-pricefeed-content {
  width: 100%;
  height: 190px;
  
  padding: 10px;

  box-sizing: border-box;
}
.ts-card-pricefeed-content-loading {
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
.ts-card-pricefeed-content-loading-text {
  width: 100%;
  text-align: center;
  color: #929CCF;
}

.ts-card-pricefeed-content-row {
  width: 100%;
  height: 21px;
}
.ts-card-pricefeed-content-row>p {
  font-size: 13px;
  height: 16px;
  line-height: 15px;
  background-color: #252842;
  transition: all ease-in 0.3s;
}
.ts-card-pricefeed-content-tag {
  float: left;
  color: #929CCF;
}
.ts-card-pricefeed-content-data {
  float: right;
  color: #CFCFCF;

  font-family: 'Roboto Mono';
}

.pricefeed-up {
  background-color: #c84a31aa !important;
  transition: all ease-in 0s  !important;
}

.pricefeed-dn {
  background-color: #336BEAaa !important;
  transition: all ease-in 0s !important;
}

.pricefeed-bb {
  color: #ffffff;
}
.pricefeed-pl {
  color: #c84a31;
}
.pricefeed-mn {
  color: #618ffd; 
}