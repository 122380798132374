.ts-label-simple {
  width: 100%;
  height: 30px;
  padding-top: 5px;
  border-radius: 35px;
  box-sizing: border-box;
}

.ts-label-simple>p {
  width: 100%;
  height: 20px;
  font-size: 15px;
  text-align: center;
  overflow: hidden;
}

.ts-label-simple-light {
  background-color: #FFFFFF;
  color: #0874FF;
}

.ts-label-simple-dark {
  background-color: #21233B;
  color: #FFFFFF;
}

.ts-label-simple-transparent {
  background-color: transparent;
  color: #FFFFFF;
}

.ts-label-simple-adaptive {
  height: 100%;
  padding-top: 2px;
}
.ts-label-simple-adaptive>p {
  height: none;
}