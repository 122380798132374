.ts-btn-sidemenu {
  width: 100%;
  height: 48px;
  margin-bottom: 10px;
  border-radius: 4px;

  background: none;
  cursor: pointer;
}

.ts-btn-sidemenu:hover {
  background: linear-gradient(90deg, #172A53 0%, #0A0E1B 100%);
}

.ts-btn-sidemenu > img {
  float: left;
  width: 17px;
  margin-top: 15px;
  margin-left: 15px;
}

.ts-btn-sidemenu > p {
  float: left;
  margin-top: 14px;
  margin-left: 10px;

  font-size: 16px;
  font-weight: 400;

  text-align: left;
  color: #929CCF;
}



.ts-btn-sidemenu-selected {
  background: linear-gradient(90deg, #172A53 0%, #0A0E1B 100%);
  cursor: default !important;
}

.ts-btn-sidemenu-selected > p {
  color: #0974FF;
}