.ts-module {
  width: 100%;
  height: 100%;

  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 25px;

  background-color: #17192D;
  border-radius: 10px;
  box-sizing: border-box;
}

.ts-module-label {
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}
.ts-module-label>h2 {
  margin:0;
  padding:0;
  font-weight: 400;
  font-size: 20px;
  line-height: 24.8px;
  color: #EEEFF1;
}

.ts-module-content {
  width: 100%;
}