.ts-input {
  width: 100%;
  margin-bottom: 12px;
}
.ts-input>p {
  width: 100%;
  margin-bottom: 12px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #929CCF;
}
.ts-input>input {
  width: 100%;
  height: 50px;

  padding: 0;
  margin: 0;
  padding-left: 17px;
  padding-right: 17px;
  
  box-sizing: border-box;
  background-color: #0A0E1B;
  border: 1px solid #0A0E1B;
  border-radius: 5px;
  
  font-size: 14px;
  text-align: left;
  color: #FFFFFF;

  outline: none;
}
.ts-input>input:focus {
  border: 1px solid #2F3251;
}