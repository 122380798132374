.ts-card-account {
  display: flex;
  width: 100%;
  height: 70px;

  margin-bottom: 10px;

  background-color: #21233B;
  border: 1px solid #21233B;
  border-radius: 8px;

  cursor: pointer;
  box-sizing: border-box;

  transition: 0.1s ease-in-out all;
  font-size: 15px;
  text-align: left;
  color: #FFFFFF;

  white-space: nowrap;
}

.ts-card-account:hover {
  border: 1px solid #3E4268;
}

.ts-card-account-selected {
  background-color: #336BEA;
}

.ts-card-account>div {
  padding-left: 20px;
  overflow: hidden;
}
.ts-card-account>div>p {
  margin-top: 26px;
}

.ts-card-account-data-1 {
  flex: 0.39;
}
.ts-card-account-data-1>p {
  margin-top: 23px !important;
  font-size: 18px;
}
.ts-card-account-data-2 {
  flex: 1.06;
}
.ts-card-account-data-3 {
  flex: 2.02;
}
.ts-card-account-data-4 {
  flex: 3.05;
}
.ts-card-account-data-5 {
  flex: 3.05;
}
.ts-card-account-data-6 {
  flex: 1.5;
  padding-right: 20px;
}

.ts-card-account-setmainbutton {
  float: right;
  width: 84px;
  height: 28px;
  margin-top: 21px;
  border-radius: 16px;
  
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  text-transform: lowercase;
  
  border: 1px solid #383B5A;
  background-color: transparent;
  color: #929CCF;

  cursor: pointer;

  transition: 0.1s ease-in-out all;
}
.ts-card-account-setmainbutton:hover {
  border: 1px solid #9B9EC0;
  color: #C2CBF6;
}

.ts-card-account-setmainbutton-selected {
  border: 1px solid #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}
.ts-card-account-setmainbutton-selected:hover {
  
}

.ts-card-account-setmainbutton-main {
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #0874FF;
}
.ts-card-account-setmainbutton-main:hover {
  cursor: default;
}