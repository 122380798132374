.ts-history-pairselector-buttons {
  display: flex;
  height: auto;
}
.ts-history-pairselector-buttons > div {
  flex: 1;
}

.ts-history-pairselector-pairs {
  display: flex;
  margin-bottom: 10px;
}
.ts-history-pairselector-pairs-1 {
  flex: 3;
  padding-right: 10px;
  box-sizing: border-box;
}
.ts-history-pairselector-pairs-2 {
  flex: 2;
}